.layout main {
  padding-top: 10px;
}
.series_image img {
  max-width: 50px;
  border-radius: 5px;
}

.articles_image img {
  width: 250px;
  height: 150px;
}

.items_images_wrap {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.items_images_image_wrap {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.items_images_image {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}

.items_images_text {
  text-align: center;
}

.RaTranslatableInputs-root {
  width: 100%;
}
